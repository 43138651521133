@import "src/app/styles/common";

.button {
    --btn-fz: #{rem(14)};
    --btn-ff: var(--ff-primary);
    --btn-fw: 600;
    --btn-min-width: 10ch;
    --btn-min-height: #{rem(35)};

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25em;
    width: fit-content;
    min-width: var(--btn-min-width);
    min-height: var(--btn-min-height);
    font-size: var(--btn-fz);
    font-family: var(--btn-ff);
    padding: 0.5em 1em;

    border-radius: 1em;
    border: none;
    outline: none;
    cursor: pointer;
    transition: $defaultTransition;
    text-transform: uppercase;

    @include xlm {
        --btn-fz: #{rem(12)};
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .buttonText {
        color: var(--clr-text-primary);
        font-size: var(--btn-fz);
        font-weight: var(--btn-fw);
        font-family: inherit;
        transition: $defaultTransition;
    }
}

.loader {
    --size: 1em;
}

.loaderBlack {
    border-top-color: var(--clr-dark);
    border-left-color: var(--clr-dark);
    border-bottom-color: var(--clr-dark);
}

.yellow {
    background: var(--theme-feature-color, var(--clr-accent));
    .buttonText {
        color: var(--clr-text-primary);
    }
}

.yellowOutlined {
    border: rem(1) solid var(--clr-accent);
    border-radius: rem(10);
    background-color: var(--clr-secondary);
    color: var(--clr-accent);
    .buttonText {
        color: currentColor;
    }
}

.green {
    background-color: var(--clr-success);
    .buttonText {
        color: var(--clr-text-primary);
    }
}

.blue {
    background-color: var(--theme-feature-color, var(--clr-blue));
}

.red {
    background-color: var(--clr-danger);
}

.black {
    background-color: var(--clr-sidepanel);
    .buttonText {
        color: var(--clr-text-primary);
    }
}

.magicWidget {
    --btn-ff: var(--ff-secondary);
    --btn-fz: #{rem(22)};
    --btn-fw: 400;
    background-color: var(--clr-grey02);
    border: rem(1) solid var(--theme-feature-color);
    padding: 0.2em 1em;

    @include xl {
        --btn-fz: #{rem(30)};
    }

    @include xlm {
        --btn-fz: #{rem(25)};
    }

    @include hover {
        border: rem(1) solid var(--theme-feature-color);
        background-color: var(--theme-feature-color);
    }

    .buttonText {
        color: var(--clr-text-primary);
    }
}

.greySquare {
    border: rem(1) solid var(--clr-border-primary);
    border-radius: 0;
    background-color: var(--clr-light80);
    height: rem(50);

    @include sm {
        height: rem(40);
    }

    .buttonText {
        position: relative;
        font-size: rem(20);

        @include sm {
            font-size: rem(16);
        }

        &::after {
            content: "";

            width: 100%;
            height: rem(2);

            background-color: var(--clr-light);

            position: absolute;
            bottom: rem(-1);
            left: 0;
        }
    }
}

.outlined {
    border: rem(1) solid var(--clr-primary);
    background-color: transparent;
    color: var(--clr-text-primary);

    .buttonText {
        color: var(--clr-text-primary);
    }
}

.transparent {
    background-color: transparent;
    border: none;
}

.icon {
    --btn-min-width: auto;
    --btn-min-height: auto;
    padding: 0;
    border-radius: 0;
}
